import React from "react"
import {css} from '@emotion/core'
import styled from '@emotion/styled'

const MetaTitle = styled.h3 `
margin: 0 auto .1rem;
font-size: .8rem;
font-weight: bold;
color: #fff;
@media (min-width: 756px) {

}
`
const H4 = styled.h4 `
margin: 0;
margin-bottom: .6rem;
font-size: .8rem;
font-weight: normal;
color: #fff;
@media (min-width: 756px) {

}
`
const MetaSerie = styled.div `

z-index: 9;
display: flex;
width: 100%;
background-color: rgb(86, 50, 201);
color: #fff;
justify-content: space-between;
padding: 1rem;
}
@media (min-width: 756px) {
  width: 45%;
  position: absolute;
right: 0;
bottom: 0;
}
`

export default props => 

<MetaSerie>
    <div css={{margin: 'auto', textAlign: 'center'}} >
        <MetaTitle>Co-production</MetaTitle>
        <H4 css={{textTransform: 'uppercase',}}>{props.coProducer}</H4>
        <MetaTitle>Avec la participation du</MetaTitle>
        <H4>{props.coFinancement}</H4>
        <MetaTitle>Diffusion</MetaTitle>
        <H4>{props.Diff}</H4>
        <MetaTitle>Dates</MetaTitle>
        <H4>{props.DiffDates}</H4>
    </div>
  <h3 css={{
    fontSize: '2rem',
    fontWeight: 'normal',
    display: 'none'
  }}>LA BANDE ANNONCE</h3>
    
</MetaSerie>