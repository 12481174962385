import React from 'react'
import Slider from "react-slick"
import "../components/slick.css"
import Perso from '../components/perso'

import Marcel from '../images/son-coming-out/marcel.png'
import Magali from '../images/son-coming-out/magali.png'
import Remi from '../images/son-coming-out/remi.png'
import Mathilde from '../images/son-coming-out/mathilde.png'
import Severine from '../images/son-coming-out/severine.png'
import Severinea from '../images/son-coming-out/severinea.png'

import FlecheSliderPr from "../images/scrine-fleche-droite.svg"
import FlecheSliderSu from "../images/scrine-fleche-gauche.svg"

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "flex", marginLeft: '1.5rem', background: "#fff", width: '1.5rem', height: '100%', position: 'absolute', top: 0, right: 0 }}
        onClick={onClick}><img alt="" src={FlecheSliderPr} css={{width: '.8rem',
        marginRight: '.5rem'}}/></div>
      
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, background: "#fff", display: "flex", zIndex: '9', marginRight: '1.5rem', width: '1.5rem', height: '100%', position: 'absolute', top: 0, left: 0 }}
        onClick={onClick}><img alt="" src={FlecheSliderSu}  css={{width: '.8rem',
        marginLeft: '.5rem'}}/></div>
    );
  }
var settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
}
const PersosMobile = ({ children }) => (
 
<div >
<Slider {...settings}>
<Perso urlEpi="https://www.france.tv/les-videos-francetv-slash/937505-mathilde-camille.html" ctaEpi="Visionner" persoPhoto={Mathilde} persoName="Mathilde et Camille" persoDesc="Mathilde raconte le coming out chaotique de sa soeur jumelle Camille, sur la terrasse de la maison familiale, ou comment deux êtres fusionnels ont fini par se retrouver après plusieurs années où l’une avait un secret à dissimuler à l’autre."></Perso>
        <Perso urlEpi="https://www.france.tv/les-videos-francetv-slash/942291-marcel-alain.html" ctaEpi="Visionner" persoPhoto={Marcel} persoName="Marcel et Alain" persoDesc="Marcel, raconte une réaction où se mêlent sidération et bienveillance, quand il apprend, par l’intermédiaire du psychologue familial, que son fils Alain est irréversiblement homosexuel, c’est-à-dire “dérangé”, comme cela se dit alors à voix basse dans la France du début des années 70. L’histoire de préjugés d’un autre âge, taillés en pièce par la vie."></Perso>
        <Perso urlEpi="https://www.france.tv/slash/son-coming-out/947217-magali-francoise.html" ctaEpi="Visionner" persoPhoto={Magali} persoName="Magali et Françoise" persoDesc="Magali raconte comment sa mère Françoise, dans une Franche-Comté où la pression sociale est très forte sur les femmes, fait exploser une cellule familiale standard en lui annonçant qu’elle est homosexuelle mais qu’elle n’a pas l’intention de quitter son père – ce qu’elle fera malgré tout, évidemment, quelques mois plus tard."></Perso>
        <Perso urlEpi="https://www.france.tv/slash/son-coming-out/951867-remi-bastien.html" ctaEpi="Visionner" persoPhoto={Remi} persoName="Rémi et Bastien" persoDesc="Rémi, père quelque peu conservateur sur les questions de mœurs, ouvertement attaché à une certaine idée de sa virilité (comme il se doit dans la bâtiment, dit-il), raconte comment il a dû instantanément se remettre en question quand il a appris, par une lettre laissée sur la table basse, que son adolescent de fils Bastien était homosexuel."></Perso>
        <Perso urlEpi="https://www.france.tv/slash/son-coming-out/957279-je-pense-que-je-ne-suis-pas-dans-le-bon-corps.html" ctaEpi="Visionner" persoPhoto={Severinea} persoName="Séverine et Austin" persoDesc="Séverine raconte comment, sur le trajet du retour du lycée, elle a obtenu de sa fille Faustine qu’elle partage la source de son mal-être intérieur, celui de “ne pas être né.e dans la bon corps”. Bientôt Faustine sera Austin, et malgré tous les immenses bouleversements cette transition de genre suscite dans cette commune du Centre de la France, Séverine ne comprend pas que son “seul bébé” ait eu peur de sa réaction."></Perso>
        <Perso urlEpi="https://www.france.tv/slash/son-coming-out/962951-severine-hugo.html" ctaEpi="Visionner" persoPhoto={Severine} persoName="Séverine et Hugo" persoDesc="Séverine, raconte un coming-out involontaire puisqu’elle a tout appris en tombant par hasard sur une lettre de son fils Hugo, jeune ado, dans laquelle celui-ci mentionne l’existence de son amoureux. Elle raconte une crise de larmes culpabilisante : pas celles de la réprobation mais celles de la peur de la réaction des autres sur son protégé."></Perso>
  </Slider>
</div>
)
export default PersosMobile
