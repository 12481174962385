import React from "react"
import {css} from '@emotion/core'
import styled from '@emotion/styled'
import MetaSerie from '../components/meta-serie'
const SerieTitle = styled.h1 `
color: #fff;
font-weight: bold;
font-size: 3rem;
margin-bottom: .6rem;
@media (min-width: 756px) {

}
`

const SerieSubtitle = styled.h2 `
color: #fff;
font-weight: normal;
@media (min-width: 756px) {

}
`
const SerieTitles = styled.div `
display: flex;
z-index: 9;
margin-top: auto;
margin-bottom: 4rem;
flex-direction: column;
text-align: center;
@media (min-width: 756px) {
  margin: auto;
}
`

export default props => 

<SerieTitles>
  <SerieTitle>{props.serieTitle}</SerieTitle>
  <SerieSubtitle>{props.serieSubtitle}</SerieSubtitle>
</SerieTitles>