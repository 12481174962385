import React from 'react'
import Slider from "react-slick"
import "../components/slick.css"
import Member from '../components/team-member'

import Cedric from '../images/son-coming-out/cedric.jpg'
import Lucile from '../images/son-coming-out/lucile.jpg'
import Seb from '../images/son-coming-out/seb.jpg'
import Clementine from '../images/son-coming-out/clementine.jpg'

import FlecheSliderPr from "../images/scrine-fleche-droite.svg"
import FlecheSliderSu from "../images/scrine-fleche-gauche.svg"

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "flex", marginLeft: '1.5rem', background: "#fff", width: '1.5rem', height: '100%', position: 'absolute', top: 0, right: 0 }}
        onClick={onClick}><img alt="" src={FlecheSliderPr} css={{width: '.8rem',
        marginRight: '.5rem'}}/></div>
      
    );
  }
  
  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, background: "#fff", display: "flex", zIndex: '9', marginRight: '1.5rem', width: '1.5rem', height: '100%', position: 'absolute', top: 0, left: 0 }}
        onClick={onClick}><img alt="" src={FlecheSliderSu}  css={{width: '.8rem',
        marginLeft: '.5rem'}}/></div>
    );
  }
var settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />
}
const MembersMobile = ({ children }) => (
 
<div >
<Slider {...settings}>
<Member memberPhoto={Cedric} memberName="Cédric Rouquette" memberPoste="Auteur" memberDesc="Journaliste, directeur et fondateur de l’agence de presse CReaFeed, directeur des études du Centre de Formation des Journalistes (CFJ) et de l’École W, il a développé depuis son passage à la rédaction en chef d’Eurosport.fr (2010-2014) un regard personnel et exigeant sur le traitement de l’actualité et des grands sujets de l’époque, qu’il met au service des médias qui le sollicitent (France Télévisions, Slate.fr, Magic…) et de ses propres projets éditoriaux."></Member>
      <Member memberPhoto={Lucile} memberName="Lucile Aimard" memberPoste="Co-réalisatrice" memberDesc="Elle a fait ses armes dans les sociétés de production Babel Prod, puis à Maximal, où elle a notamment participé au lancement de l’émission Questions Directes. En 2018, elle s’est spécialisée sur des témoignages sensibles dans les différentes productions auxquelles elle a participé, notamment Sectes des Douze Tribus (2018, Babel Doc, 13', diffusé sur 66 Minutes, M6) ou Les Français à Delhi, 2018 (Babel Press, 45', diffusé dans French in the city, M6)."></Member>
      <Member memberPhoto={Clementine} memberName="Clémentine Billé" memberPoste="Journaliste" memberDesc="Enquêtrice, elle a notamment collaboré pendant plus d’un an sur des documentaires portant sur les dérives sectaires et religieuses. Elle a travaillé sur le documentaire L’Exercice de la Justice de Michaël Prazan (110 min, TV-presse - France 3) et La face cachée du Front national (TV-Presse, C8) puis s’est spécialisée sur les questions de sexualité, où les médias en ligne Vice et Slate ont déjà eu recours à son expertise."></Member>
      <Member memberPhoto={Seb} memberName="Sébastien Bossi Croci" memberPoste="Co-réalisateur" memberDesc="Entré très tôt dans le monde du journalisme en ligne à la direction de la rédaction d’Ijsberg (lauréat du prix Google en innovation dans le journalisme 2014), puis du média politique éphémère 2k17 (20 millions de vues), il a vu différents travaux individuels ou collectifs publiés dans la série L’Explore de L’Equipe, sur lemonde.fr ou auprès des clients de l’agence UXO où il est directeur éditorial. Il enseigne le journalisme mobile, le journalisme en ligne et la culture numérique au CFJ, à l’Ecole W et à SportCom."></Member>

  </Slider>
</div>
)
export default MembersMobile