import React from 'react'
import SEO from '../components/seo'
import Layout from '../components/layout'
import SerieTitles from '../components/header-serie'
import MetaSerie from '../components/meta-serie'
import Perso from '../components/perso'
import {css} from '@emotion/core'
import styled from '@emotion/styled'
import Apropos from '../components/a-propos'
import Member from '../components/team-member'
import PersosMobile from '../components/persos-mobile'
import MembersMobile from '../components/members-mobile'

import Marcel from '../images/son-coming-out/marcel.png'
import Magali from '../images/son-coming-out/magali.png'
import Remi from '../images/son-coming-out/remi.png'
import Mathilde from '../images/son-coming-out/mathilde.png'
import Severine from '../images/son-coming-out/severine.png'
import Severinea from '../images/son-coming-out/severinea.png'

import Cedric from '../images/son-coming-out/cedric.jpg'
import Lucile from '../images/son-coming-out/lucile.jpg'
import Seb from '../images/son-coming-out/seb.jpg'
import Clementine from '../images/son-coming-out/clementine.jpg'

import Cover from '../images/son-coming-out/cover-coming-out.jpg'
import CoverMobile from '../images/son-coming-out/cover-mobile.jpg'
import CoverTeam from '../images/son-coming-out/cover-team-coming-out.jpg'
import Sharing from '../images/sharing-coming-out.jpg'

import Scrine from '../images/scrine.svg'
import Slash from '../images/slash.svg'
import CNC from '../images/cnc.svg'

const SerieHeader = styled.div `
position: relative;
height: 110vh;
display: flex;
margin-top: -4rem;
flex-direction: column;
@media (min-width: 760px) {
  margin-top: -7vw;
  height: 100vh;
}

`
const P = styled.p `
max-width: 35rem;
margin: 2rem auto;
`
const H2 = styled.h2 `
text-transform: uppercase;
text-align: center;
font-weight: bold;
`

const Persos = styled.div `
display: none;
@media (min-width: 760px) {
display: flex;
flex-wrap: wrap;
margin: auto;
max-width: 86%;
}
`
const TeamMembers = styled.div `
display: none;
@media (min-width: 760px) {
display: flex;
flex-wrap: wrap;
margin: auto;
max-width: 86%;
}
`
const Logos = styled.div `
background-color: #fff;
height: 3rem;
z-index: 8;
display: flex;
justify-content: space-evenly;
align-items: center;
@media (min-width: 756px) {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 3rem;
  width: 55%;

}

`
const Logo = styled.img `
height: 1rem;
margin-bottom: 0;

@media (max-width: 756px) {
height: 1rem;

}
`

const SonComingOut = () => (
  <Layout>
     <SEO title="Son Coming-Out" description="Son Coming-Out est une série documentaire diffusée par France.tv Slash, produite et réalisée par Scrine sur le coming-out" image={Sharing}/>
    <SerieHeader css={{
      backgroundImage: `url(${Cover})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat'
    }}>
    
      <img src={CoverMobile} 
      css={{
        width: '100vw',
        height: 'auto',
        top: 0,
        left: 0,
        position: 'absolute',
        display: 'none',
        '@media (max-width: 760px)' : {
          display: 'block',
        },
      }}
      />
      <SerieTitles serieTitle="Son Coming-Out" serieSubtitle="Une série documentaire Scrine"></SerieTitles>
      <Logos>
        <Logo src={Slash} />
        <Logo src={CNC} />
        <Logo src={Scrine} css={{height: '1.4rem'}}/>
      </Logos>
      <MetaSerie coProducer="Scrine - France Télévisions" coFinancement="CNC" Diff="Les plateformes france.tv Slash" DiffDates="Mars - avril 2019"></MetaSerie>

    </SerieHeader>
    <section css={{
      maxWidth: '86%',
      margin: '6rem auto'
    }}>
       <P>Faire son coming-out revient, pour une personne homosexuelle ou en transition de genre, à réaliser un grand saut dans l’inconnu. Il ne lui suffit pas de décider d’assumer son orientation : les autres, eux aussi, doivent décider. Décider de l’accepter dans sa plénitude ou de la renvoyer à sa rupture avec une hypothétique normalité. Décider d’accompagner son choix ou de le déconsidérer. Décider de renvoyer de l’amour ou de l’indifférence. Et cette décision-là aura, elle aussi, une valeur définitive.
</P>
<P>Elles n’en ont pas toujours conscience, mais les personnes de confiance qui “reçoivent” le coming-out sont des acteurs centraux de ces instants de vie décisifs, au moins autant que les personnes qui ont choisi de leur faire confiance. La série documentaire “Son Coming-Out” (6 x 6 minutes diffusées en ligne) raconte du point de vue de “l’autre” ces moments où des existences basculent. L’autre : le confident, celui ou celle qui a été élu.e pour recevoir ce fragment d’intimité.</P>
<P>Co-produite par la société de production Scrine et France Télévisions pour son média Slash à destination des millenials, la série “Son Coming-Out” a été conçue à partir de témoignages très personnels, puisés auprès de toutes les générations, dans toutes les couches de la population française, là où des enfants, des sœurs, des parents homosexuels, ont pu entamer une nouvelle vie, souvent plus épanouie, parce que leur interlocuteur avait fait savoir, à leur façon, que oui, bien sûr, tout irait bien.</P>
 <P>Sans angélisme par rapport à une réalité sociale encore souvent trop cruelle pour des personnes que leur entourage rejette, cette série entend donner au spectateur des clés pour l’aider à nourrir son bagage émotionnel s’il devait lui aussi, un jour, recevoir un tel message et s’il souhaitait permettre à la personne de se construire, de se libérer d’un poids et de se voir reconnue et acceptée pleinement.</P>    
    </section>
    <section css={{
      marginTop: '8rem',
    }}>
      <H2>les épisodes</H2>
      <Persos>  
        <Perso urlEpi="https://www.france.tv/les-videos-francetv-slash/937505-mathilde-camille.html" ctaEpi="Visionner" persoPhoto={Mathilde} persoName="Mathilde et Camille" persoDesc="Mathilde raconte le coming out chaotique de sa soeur jumelle Camille, sur la terrasse de la maison familiale, ou comment deux êtres fusionnels ont fini par se retrouver après plusieurs années où l’une avait un secret à dissimuler à l’autre."></Perso>
        <Perso urlEpi="https://www.france.tv/les-videos-francetv-slash/942291-marcel-alain.html" ctaEpi="Visionner" persoPhoto={Marcel} persoName="Marcel et Alain" persoDesc="Marcel, raconte une réaction où se mêlent sidération et bienveillance, quand il apprend, par l’intermédiaire du psychologue familial, que son fils Alain est irréversiblement homosexuel, c’est-à-dire “dérangé”, comme cela se dit alors à voix basse dans la France du début des années 70. L’histoire de préjugés d’un autre âge, taillés en pièce par la vie."></Perso>
        <Perso urlEpi="https://www.france.tv/slash/son-coming-out/947217-magali-francoise.html" ctaEpi="Visionner" persoPhoto={Magali} persoName="Magali et Françoise" persoDesc="Magali raconte comment sa mère Françoise, dans une Franche-Comté où la pression sociale est très forte sur les femmes, fait exploser une cellule familiale standard en lui annonçant qu’elle est homosexuelle mais qu’elle n’a pas l’intention de quitter son père – ce qu’elle fera malgré tout, évidemment, quelques mois plus tard."></Perso>
        <Perso urlEpi="https://www.france.tv/slash/son-coming-out/951867-remi-bastien.html" ctaEpi="Visionner" persoPhoto={Remi} persoName="Rémi et Bastien" persoDesc="Rémi, père quelque peu conservateur sur les questions de mœurs, ouvertement attaché à une certaine idée de sa virilité (comme il se doit dans la bâtiment, dit-il), raconte comment il a dû instantanément se remettre en question quand il a appris, par une lettre laissée sur la table basse, que son adolescent de fils Bastien était homosexuel."></Perso>
        <Perso urlEpi="https://www.france.tv/slash/son-coming-out/957279-je-pense-que-je-ne-suis-pas-dans-le-bon-corps.html" ctaEpi="Visionner" persoPhoto={Severinea} persoName="Séverine et Austin" persoDesc="Séverine raconte comment, sur le trajet du retour du lycée, elle a obtenu de sa fille Faustine qu’elle partage la source de son mal-être intérieur, celui de “ne pas être né.e dans la bon corps”. Bientôt Faustine sera Austin, et malgré tous les immenses bouleversements cette transition de genre suscite dans cette commune du Centre de la France, Séverine ne comprend pas que son “seul bébé” ait eu peur de sa réaction."></Perso>
        <Perso urlEpi="https://www.france.tv/slash/son-coming-out/962951-severine-hugo.html" ctaEpi="Visionner" persoPhoto={Severine} persoName="Séverine et Hugo" persoDesc="Séverine, raconte un coming-out involontaire puisqu’elle a tout appris en tombant par hasard sur une lettre de son fils Hugo, jeune ado, dans laquelle celui-ci mentionne l’existence de son amoureux. Elle raconte une crise de larmes culpabilisante : pas celles de la réprobation mais celles de la peur de la réaction des autres sur son protégé."></Perso>
      </Persos>
      <div css={{
        display: 'none',
        '@media (max-width: 760px)' : {
          display: 'block',
        },
      }}>
      <PersosMobile
      /></div>
    </section>
    <section css={{
      marginTop: '8rem',
      marginBottom: '6rem'
    }}>
      <H2>l'équipe</H2>
      <img src={CoverTeam}
      css={{
        width: '100vw',
        height: 'auto',
      }}/>
<P css={{
  textAlign: 'center',
  maxWidth: '86%',
}}>Sur une idée originale de Sébastien Bossi Croci et Cédric Rouquette, ont notamment travaillé sur “Son Coming-Out” :</P>
    <TeamMembers>
      <Member memberPhoto={Cedric} memberName="Cédric Rouquette" memberPoste="Auteur" memberDesc="Journaliste, directeur et fondateur de l’agence de presse CReaFeed, directeur des études du Centre de Formation des Journalistes (CFJ) et de l’École W, il a développé depuis son passage à la rédaction en chef d’Eurosport.fr (2010-2014) un regard personnel et exigeant sur le traitement de l’actualité et des grands sujets de l’époque, qu’il met au service des médias qui le sollicitent (France Télévisions, Slate.fr, Magic…) et de ses propres projets éditoriaux."></Member>
      <Member memberPhoto={Lucile} memberName="Lucile Aimard" memberPoste="Co-réalisatrice" memberDesc="Elle a fait ses armes dans les sociétés de production Babel Prod, puis à Maximal, où elle a notamment participé au lancement de l’émission Questions Directes. En 2018, elle s’est spécialisée sur des témoignages sensibles dans les différentes productions auxquelles elle a participé, notamment Sectes des Douze Tribus (2018, Babel Doc, 13', diffusé sur 66 Minutes, M6) ou Les Français à Delhi, 2018 (Babel Press, 45', diffusé dans French in the city, M6)."></Member>
      <Member memberPhoto={Clementine} memberName="Clémentine Billé" memberPoste="Journaliste" memberDesc="Enquêtrice, elle a notamment collaboré pendant plus d'un an sur des documentaires portant sur les dérives sectaires et religieuses. Elle a travaillé sur le documentaire L'Exercice de la Justice de Michaël Prazan (110 min, TV-presse - France 3) et La face cachée du Front national (TV-Presse, C8) puis est s’est spécialisée sur les questions de sexualité, où les médias en ligne Vice et Slate ont déjà eu recours à son expertise. "></Member>
      <Member memberPhoto={Seb} memberName="Sébastien Bossi Croci" memberPoste="Co-réalisateur" memberDesc="Entré très tôt dans le monde du journalisme en ligne à la direction de la rédaction d’Ijsberg (lauréat du prix Google en innovation dans le journalisme 2014), puis du média politique éphémère 2k17 (plusieurs millions de vues), il a vu différents travaux individuels ou collectifs publiés dans la série L’Explore de L’Equipe, sur lemonde.fr ou auprès des clients de l’agece UXO où il est directeur éditorial. Il enseigne le journalisme mobile, le journalisme en ligne et la culture numérique au CFJ, à l’Ecole W et à SportCom."></Member>
    </TeamMembers>
    <div css={{
        display: 'none',
        '@media (max-width: 760px)' : {
          display: 'block',
        },
      }}>
      <MembersMobile /></div>
    
    </section>
    <Apropos />
  </Layout>
)

export default SonComingOut
