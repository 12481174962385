import React from "react"
import {css} from '@emotion/core'
import styled from '@emotion/styled'


const Name = styled.h3 `
text-transform: uppercase;
font-weight: normal;
margin-bottom: .6rem;
`
const Perso = styled.div `
width: 86%;
text-align: center;
margin: 2rem auto;
@media (min-width: 756px) {
  width: 30%;
}
`
const PersoDesc = styled.p `
font-size: .8rem;
line-height: 1.3;
max-width: 86%;
margin: auto;
`
const Photo = styled.img `
width: 150px;
margin: 0 auto .6rem;
height: auto;
`
const LienEpi = styled.a `
background-color: rgb(142, 239, 253);
    font-family: greycliff;
    font-size: .8rem;
    font-weight: bold;
    margin-top: 1rem;
    color: rgb(51, 60, 86);
    display: inline-block;
    padding: 0.3rem 1.5rem;
    text-decoration: none;
    box-shadow: rgb(247, 64, 198) 6px 6px 0px -1px;
    @media (max-width: 756px) {
      margin-top: 1.4rem;
    }
}
`

export default props => 

<Perso>
  <Photo src={props.persoPhoto} />
  <Name>{props.persoName}</Name>
  <PersoDesc>{props.persoDesc}</PersoDesc>
  <LienEpi href={props.urlEpi} target="_blank">{props.ctaEpi}</LienEpi>
</Perso>
