import React from "react"
import {css} from '@emotion/core'
import styled from '@emotion/styled'


const Name = styled.h3 `
text-transform: uppercase;
font-weight: normal;
margin-bottom: .3rem;
font-size: 1.2rem;
`
const Member = styled.div `
width: 86%;
text-align: center;
margin: .6rem auto;
@media (min-width: 756px) {
  width: 40%;
}
`
const MemberDesc = styled.p `
font-size: .8rem;
max-width: 86%;
line-height: 1.3;
margin: auto;
`
const Photo = styled.img `
width: 150px;
height: auto;
margin: auto;
margin-bottom: .6rem;
border-radius: 50%;
`
const Poste = styled.p `
margin-bottom: .6rem;

`
export default props => 

<Member>
  <Photo src={props.memberPhoto} />
  <Name>{props.memberName}</Name>
  <Poste>{props.memberPoste}</Poste>
  <MemberDesc>{props.memberDesc}</MemberDesc>
</Member>
