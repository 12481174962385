import React from "react"
import {css} from '@emotion/core'
import styled from '@emotion/styled'


const Apropos = styled.div `
background-color: rgb(86,50,201);
color: #fff;
padding: 3rem;
`

const H3 = styled.h3 `
text-align: center;
text-transform: uppercase;
margin-bottom: .6rem;
font-weight: normal;
`
const H2 = styled.h2 `
text-align: center;
margin-bottom: .6rem;
font-weight: bold;

`

const P = styled.p `
max-width: 35rem;
margin: 2rem auto;
`

export default props => 
<Apropos>
  <H3>à propos de</H3>
  <H2>scrine</H2>
  <div>
    <P>Née en décembre 2018 des activités communes de l’agence de presse CReaFeed et de l’agence digitale UXO, Scrine est une société de production positionnée sur la vidéo en ligne à destination des millenials et les nouvelles formes de storytelling. Elle permet à une jeune équipe d’auteurs, réalisateurs et journalistes de porter une vision exigeante de l’écriture vidéo en ligne, sur laquelle la demande des diffuseurs est croissante (en volume et en qualité) dans un univers multi-écrans et délinéarisé. Scrine pense que même si l’attention du public est plus difficile à capter à une époque où les sollicitations sont exponentielles, la qualité éditoriale et le soin apporté à la mise en image permettront aux contenus porteurs de sens d’émerger.</P>
    <P>Les fondateurs et inspirateurs de Scrine ont affûté leur pratique professionnelle au sein des agences Creafeed et UXO, associées dans plusieurs projets de formats vidéos et de web-documentaires. Leur précédent projet, L’Amour L’Été (co-production France Télévisions - Creafeed, diffusion été 2018, 10 épisodes de 5 minutes) donne un aperçu d’une exigence éditoriale où se rencontrent la valeur des témoignages, la mise en images des lieux de vie et la capacité à mettre en relief le fond du propos à travers des choix de réalisations dénués de tout effet gratuit.</P>
    <P>Les auteurs et journalistes de Scrine avaient aussi réalisé ensemble le média éphémère 2K17 (l’élection présidentielle par et pour les 18-25 ans), où le format du web-documentaire avait déjà une place importante, notamment à travers le format IRL, qui suivait cinq jeunes électeurs à différents étapes de la campagne, afin d’enregistrer leur sensibilité aux différentes propositions en circulation dans le débat public.</P>
  </div>
  </Apropos>
